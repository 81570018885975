.App{
    text-align: center;
    width: 350px;
    margin: 0 auto;
    margin-top: 30px;
}

.App-logo{
    height: 40vmin;
    pointer-events: none;
}